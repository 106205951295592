<template>
  <div class="content">
    <head-layout :head-btn-options="headBtnOptions"
                 head-title="功能设计"
                 @head-remove="handleDelete"
                 @head-add-tabs="headAdd"></head-layout>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-data="tableData"
      :table-loading="tableLoading"
      :table-options="tableOptions"
      :page="page"
      :data-total="page.total"
      @page-current-change="gridHeadSearch"
      @page-size-change="gridHeadSearch"
      @page-refresh-change="gridHeadSearch"
      @row-edit="rowEdit"
      @row-remove="rowRemove"
      @row-publish="rowPublish"
      @gird-handle-select-click="selectionChange"
    ></grid-layout>
    <AddBox :visible.sync="addVisible" :webType="currWebType" @add="handleAdd"/>
    <Form v-if="formVisible" ref="Form" @close="closeForm"/>
    <previewDialog :visible.sync="previewDialogVisible" :id="currId" :previewType="previewType"
                   type="webDesign"/>
    <el-dialog title="同步菜单" :visible.sync="releaseDialogVisible"
               class="SNOWS-dialog SNOWS-dialog_center release-dialog" lock-scroll width="600px">
      <el-alert title="将该功能的按钮、列表、表单及数据权限同步至系统菜单" type="warning" :closable="false" show-icon/>
      <div class="dialog-main">
        <div class="item" :class="{'active':releaseQuery.pc == true}" @click="selectToggle('pc')">
          <i class="item-icon icon-ym icon-ym-pc"></i>
          <p class="item-title">同步Web端菜单</p>
          <div class="icon-checked">
            <i class="el-icon-check"></i>
          </div>
        </div>
        <div class="item" :class="{'active':releaseQuery.app == true}" @click="selectToggle('app')">
          <i class="item-icon icon-ym icon-ym-mobile"></i>
          <p class="item-title">同步APP端菜单</p>
          <div class="icon-checked">
            <i class="el-icon-check"></i>
          </div>
        </div>
      </div>
      <el-form class="dialog-form-main" :model="releaseQuery" :rules="releaseQueryRule"
               label-position="right" label-width="50px" ref="releaseForm">
        <div style="display: flex;justify-content: space-between;width: 100%;">
          <div style="width: 50%;">
            <template>
              <el-form-item label="上级" prop="pcModuleParentId" v-if="releaseQuery.pc">
                <avue-input-tree default-expand-all v-model="parentMenu" placeholder="请选择内容"
                                 :dic="menuData"></avue-input-tree>
              </el-form-item>
            </template>
          </div>
          <div style="width: 50%;">
            <template>
              <el-form-item label="上级" prop="pcModuleParentId" v-if="releaseQuery.app">
                <avue-input-tree default-expand-all v-model="appMenu" placeholder="请选择内容"
                                 :dic="appTreeData"></avue-input-tree>
              </el-form-item>
            </template>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="releaseDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="releaseBtnLoading" @click="releaseModel">
          确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {listData, menuPublish, menuTree, deleteData} from '@/api/systemData/abilityDesign';
import AddBox from '@/views/generator/AddBox'
import Form from './Form'
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {getLazyAppList, getLazyList, update} from "@/api/system/menu";

export default {
  components: {Form, AddBox, GridLayout},
  data() {
    return {
      menuData: [],
      parentMenu: '',
      appMenu: '',
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      releaseBtnLoading:false,
      query: {keyword: '', type: 1},
      tableLoading: false,
      releaseDialogVisible: false,
      tableData: [],
      previewDialogVisible: false,
      previewType: "",
      dialogVisible: false,
      formVisible: false,
      currWebType: '',
      addVisible: false,
      pcDis:true,
      appDis:true,
      currRow: {
        pcIsRelease: false,
        appIsRelease: false
      },
      appTreeData: [],
      rowData: {},
      releaseQuery: {
        pc: true,
        app: true,
        pcModuleParentId: '',
        appModuleParentId: '',
        systemId: ''
      },
      selectionList: []
    }
  },
  computed: {
    ids() {
      let ids = this.selectionList.map(item => item.id);
      return ids.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    searchColumns() {
      return [
        {
          label: '表名',
          prop: "title",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入表名',
          type: 'input',
        },
        {
          label: '数据链接',
          prop: "dataBase",
          dicData: this.dataLink,
          props: {
            label: "dbName",
            value: "id"
          },
          span: 4,
          type: 'select',
          placeholder: '请选择数据链接'
        },
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: '序号',
        menuWidth: 200,
        column: [
          {
            label: '名称',
            prop: "fullName"
          },
          {
            label: '编码',
            prop: "enCode",
          },
          // {
          //   label: '模式',
          //   prop: "enCode",
          // },
          // {
          //   label: '启用流程',
          //   prop: "enCode",
          // },
          // {
          //   label: '创建人',
          //   prop: "enCode",
          // },
          // {
          //   label: '创建时间',
          //   prop: "enCode",
          // },
          // {
          //   label: '最后修改时间',
          //   prop: "enCode",
          // },
          // {
          //   label: '状态',
          //   prop: "enCode",
          // },
        ]
      }
    },
    gridRowBtn() {
      return [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
        },
        {
          label: '发布',
          emit: "row-publish",
          type: "button",
        },
      ]
    }
  },
  mounted() {
    this.getMenuTree();
    this.onload(this.page)
  },
  methods: {
    selectionChange(list) {
      this.selectionList = list;
    },
    //批量删除
    handleDelete() {
      if (this.ids == '') {
        this.$message({
          message: '请选择要删除的数据',
          type: 'error',
          duration: 1500,
        })
        return;
      }
      deleteData({
        ids: this.ids
      }).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success',
          onClose: () => {
            this.gridHeadEmpty();
          }
        });
      })
    },
    //单删
    rowRemove(row) {
      deleteData({
        ids: row.id
      }).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success',
          onClose: () => {
            this.gridHeadEmpty();
          }
        });
      })
    },
    //编辑
    rowEdit(row, webType, isToggle) {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.Form.init(row.id, this.query.type, webType, isToggle)
      })
    },
    getMenuTree() {
      menuTree().then(res => {
        let data = this.matterTree(res.data.data);
        this.menuData = data;
      })
      getLazyAppList(
        0,
        1,
        100
      ).then((res) => {
        this.appTreeData = res.data.data.records;
        this.appTreeData.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
        })
      })
    },
    matterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].title;
        data[i].value = data[i].id;
        if (data[i].children && data[i].children.length > 0) {
          this.matterTree(data[i].children)
        }
      }
      return data
    },
    releaseModel() {
      this.releaseBtnLoading = true;
      if (this.releaseQuery.pc == true) {
        let alias = {
          en: this.rowData.fullName,
          zh: this.rowData.fullName
        }
        menuPublish({
          action: 0,
          actionName: "",
          alias: JSON.stringify(alias),
          category: 1,
          categoryName: "菜单",
          code: this.rowData.enCode,
          color: "",
          defaultLanguage: "",
          filePath: "",
          hasChildren: false,
          isDeleted: 0,
          isOnline: '2',
          isOpen: 1,
          meta: {
            relationId: this.rowData.id
          },
          isOpenName: "否",
          name: JSON.stringify(alias),
          parentId: this.parentMenu,
          path: '/' + this.rowData.enCode,
          platform: "1",
          remark: "",
          sort: 1,
          source: "iconfont iconicon_GPS",
          status: 0,
          systemLanguage: "",
          typeSetting: 0,
        }).then((res) => {
          this.$message({
            message: res.data.msg,
            type: 'success',
            onClose: () => {
              this.releaseDialogVisible = false;
              this.releaseBtnLoading = false;
              location.reload();
            }
          });
        })
      }
      if (this.releaseQuery.app == true) {
        let alias = {
          en: this.rowData.fullName,
          zh: this.rowData.fullName
        }
        let appObj = {
          $category: '菜单',
          $platform: 'APP',
          code: this.rowData.enCode,
          name: JSON.stringify(alias),
          parentId: this.appMenu,
          path: '/' + this.rowData.enCode,
          platform: '2',
          sort: '1',
          category: 1,
          isOnline:'2',
          fullName: this.rowData.fullName,
          abilityId: this.rowData.id
        }
        update(appObj).then((res) => {
          this.$message({
            message: res.data.msg,
            type: 'success',
            onClose: () => {
              this.releaseDialogVisible = false;
              this.releaseBtnLoading = false;
            }
          });
        })
      }
      if (this.releaseQuery.pc == false && this.releaseQuery.app == false) {
        this.$message({
          message: "请选择要发布的平台",
          type: 'error',
        });
        this.releaseBtnLoading = false;
      }
    },
    //新增
    headAdd() {
      this.addVisible = true;
    },
    //搜索
    gridHeadSearch() {
      this.onload(this.page)
    },
    //清空搜索条件
    gridHeadEmpty() {
      this.onload(this.page)
    },
    rowPublish(row) {
      this.rowData = row;
      getLazyList(
        0,
        1,
        20,
        {
          name: row.fullName,
          code: row.enCode
        }
      ).then(res => {
        let total = res.data.data.total;
        if (total > 0) {
          this.releaseQuery.pc = false;
          this.pcDis = false;
        }
      });
      getLazyAppList(
        0,
        1,
        100,
        {
          name:row.fullName
        }
      ).then((res) => {
        let total = res.data.data.total;
        if (total > 0) {
          this.releaseQuery.app = false;
          this.appDis = false;
        }
      })
      this.releaseDialogVisible = true;
    },
    selectToggle(data) {
      if (data == 'pc'){
        if (this.pcDis == false) return;
        this.releaseQuery.pc = !this.releaseQuery.pc
      } else if (data == 'app') {
        if (this.appDis == false) return;
        this.releaseQuery.app = !this.releaseQuery.app
      }
    },
    onload(page) {
      this.tableLoading = true;
      this.page = page;
      listData(page.currentPage, page.pageSize).then((res) => {
        const data = res.data.data;
        this.tableData = data.records;
        this.page.total = data.total;
        this.tableLoading = false;
      })
    },
    handleAdd(webType, isToggle) {
      this.addOrUpdateHandle(this.currId, webType, isToggle)
    },
    addOrUpdateHandle(id, webType, isToggle) {
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.Form.init(id, this.query.type, webType, isToggle)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.release-dialog {
  ::v-deep .el-dialog {
    .el-dialog__body {
      padding: 12px 55px;
    }
  }

  .dialog-form-main {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    ::v-deep .el-form-item {
      width: 215px;
    }
  }

  .dialog-main {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .item {
      position: relative;
      width: 215px;
      height: 127px;
      cursor: pointer;
      border: 1px solid #dcdfe6;
      border-radius: 6px;
      text-align: center;
      padding-top: 20px;
      color: #606266;

      &.active {
        border-color: #1890ff;
        color: #1890ff;
        box-shadow: 0 0 6px rgba(6, 58, 108, 0.1);

        .item-icon {
          border-color: #1890ff;
        }

        .icon-checked {
          display: block;
        }
      }

      .item-icon {
        display: inline-block;
        width: 44px;
        height: 44px;
        margin-bottom: 16px;
        border: 2px solid #606266;
        line-height: 40px;
        font-size: 24px;
        text-align: center;
        border-radius: 50%;
      }

      .item-title {
        font-size: 16px;
        font-weight: 400;
      }

      .icon-checked {
        display: none;
        width: 18px;
        height: 18px;
        border: 18px solid #1890ff;
        border-left: 18px solid transparent;
        border-top: 18px solid transparent;
        border-bottom-right-radius: 4px;
        position: absolute;
        right: 0px;
        bottom: 0px;

        i {
          font-size: 16px;
          position: absolute;
          top: 0;
          left: -2px;
          color: #fff;
        }
      }
    }
  }
}
</style>

